import { useEffect, useMemo } from "react"
import { Link } from "eddev/routing"
import { useAppData } from "eddev/hooks"
import { MenuItemFieldsFragment, MenuLocationEnum } from "../../types.graphql"

/**
 * Feel free to adjust this file to meet your needs! Keep in mind that you shouldn't
 * add any styling to this file, as it's meant to be a generic component.
 *
 * To style a menu, use styled(Menu), and target the ul, li and a individually.
 */

type MenuItemsProps = {
  items: MenuItemFieldsFragment[]
  className?: string
  depth: number
}

function MenuItems(props: MenuItemsProps) {
  if (props.items) {
    return (
      <ul data-menu-depth={props.depth}>
        {props.items.map((item, i) => (
          <li key={item.label} className={item.cssClasses?.length ? item.cssClasses.join(" ") : undefined}>
            <Link href={item.url!} target={item.target!}>
              {item.label}
            </Link>
            {item.childItems?.nodes?.length! > 0 && (
              <MenuItems depth={props.depth + 1} items={item.childItems!.nodes as MenuItemFieldsFragment[]} />
            )}
          </li>
        ))}
      </ul>
    )
  }
  return null
}

type Props = {
  location: keyof typeof MenuLocationEnum
  className?: string
}

export function Menu(props: Props) {
  const menus = useAppData((appData) => appData.menus?.nodes)
  const menu = menus?.find((menu) => menu?.locations?.includes(MenuLocationEnum[props.location]))
  if (env.dev) {
    useEffect(() => {
      if (!menu) {
        console.error(`No menu for location "${props.location}".`)
      }
    }, [menu, props.location])
  }

  if (menu?.menuItems?.nodes) {
    return (
      <MenuItems
        className={props.className}
        depth={0}
        items={menu.menuItems.nodes.filter((p) => !p.parentId) as MenuItemFieldsFragment[]}
      />
    )
  } else {
    return null
  }
}
