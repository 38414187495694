import _app from "../../../views/_app.tsx";

import { viewManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
const manifest = {
  "404": dynamic(() => import("../../../views/404.tsx")),
  "_app": _app,
  "category": dynamic(() => import("../../../views/category.tsx")),
  "front-page": dynamic(() => import("../../../views/front-page.tsx")),
  "page": dynamic(() => import("../../../views/page.tsx")),
  "single": dynamic(() => import("../../../views/single.tsx")),
  "single-career": dynamic(() => import("../../../views/single-career.tsx")),
  "single-project": dynamic(() => import("../../../views/single-project.tsx")),
  "taxonomy-project-category": dynamic(() => import("../../../views/taxonomy-project-category.tsx")),
  "templates/company": dynamic(() => import("../../../views/templates/company.tsx")),
  "templates/contact": dynamic(() => import("../../../views/templates/contact.tsx")),
  "templates/projects": dynamic(() => import("../../../views/templates/projects.tsx")),
  "templates/search": dynamic(() => import("../../../views/templates/search.tsx")),
  "templates/services": dynamic(() => import("../../../views/templates/services.tsx")),
  "templates/thinking": dynamic(() => import("../../../views/templates/thinking.tsx")),
};

viewManifestReader.value = manifest;

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}
