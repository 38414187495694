import { create } from "zustand"

type NavStore = {
  open: boolean
  setOpen(data: boolean): void
}

export const useNav = create<NavStore>((set, get) => ({
  open: false,
  setOpen: (data) => set({ open: data }),
}))
