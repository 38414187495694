import { create } from "zustand"

type PersonPopoutStore = {
  open: boolean
  setOpen(data: boolean): void
  uri: string | null
  setUri(data: string | null): void
}

export const usePersonPopout = create<PersonPopoutStore>((set, get) => ({
  open: false,
  setOpen: (data) => set({ open: data }),
  uri: null,
  setUri: (data) => set({ uri: data }),
}))
