import { ARROW_UP } from "@components/atoms/Icons"
import { Logo, LogoMark, OCTAL } from "@components/atoms/Logo"
import { Menu } from "@components/atoms/Menu"
import { Link, useRouter } from "eddev/routing"
import { useState } from "react"
import { Fragment } from "react/jsx-runtime"

export function Footer() {
  const OctalArray = Array.apply(null, Array(12))
  const router = useRouter()

  return (
    <div className="bg-black text-white py-7 md:py-5">
      <div className="grid-auto items-center md:w-full md:px-4">
        <div className="col-span-8 flex justify-start order-1 md:col-span-3 md:flex-col md:items-start md:h-full">
          <i className="block w-[40px] h-[52px] md:w-[192px] md:h-[31px]">
            <Link href="/">
              <span className="block md:hidden">
                <LogoMark />
              </span>
              <span className="hidden md:block">
                <Logo />
              </span>
            </Link>
          </i>

          <div className="hidden py-4 flex-wrap md:flex">
            {OctalArray.map((_, i) => (
              <Fragment key={i}>
                <FooterOctal />
              </Fragment>
            ))}
          </div>
        </div>

        <div className="col-span-4 h-full flex items-start justify-end order-2 md:hidden">
          <button
            className="background-transparent p-2 border-0 rounded-full"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" })
            }}
          >
            {ARROW_UP}
          </button>
        </div>

        <div className="col-span-12 order-3 md:hidden">
          <div className="block h-10 md:hidden" />
        </div>

        <div className="menu-footer col-span-12 order-4 md:order-2 md:col-span-9 md:flex md:flex-col md:justify-between md:h-full">
          <div className="flex justify-between">
            <div className="w-full md:w-10/12">
              <Menu location="Footer" />
            </div>

            <div className="hidden md:flex md:w-2/12 md:justify-end md:items-start">
              <button
                className="background-transparent p-2 border-0 rounded-full"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" })
                }}
              >
                {ARROW_UP}
              </button>
            </div>
          </div>

          <form className="w-full pb-8 hidden md:block">
            <input
              className="type-title-m-light w-full bg-transparent border-b py-4 placeholder:text-[#7D7D7D]"
              type="text"
              placeholder="Search site"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                  router?.navigate(`/search?q=${encodeURIComponent(e.currentTarget.value)}`)
                }
              }}
            />
          </form>
        </div>

        <div className="col-span-12 order-5 md:order-7 md:col-span-1">
          <div className="block h-10 md:hidden" />
        </div>

        <div className="col-span-12 order-6 md:hidden">
          <form className="w-full">
            <input
              className="type-title-m-light w-full bg-transparent border-b py-4"
              type="text"
              placeholder="Search site"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                  router?.navigate(`/search?q=${encodeURIComponent(e.currentTarget.value)}`)
                }
              }}
            />
          </form>
        </div>

        <div className="col-span-12 order-7 md:order-8 md:hidden">
          <div className="block h-9" />
        </div>

        <div className="col-span-6 flex justify-start items-start h-full order-8 md:order-4 md:col-span-2">
          <span className="type-ui-xs-caps">
            &copy; 2024
            <br />
            ITP Renewables
          </span>
        </div>

        <div className="col-span-6 flex justify-end items-start h-full order-9 md:order-4 md:col-span-1 md:justify-start">
          <Link
            className="type-ui-xs-caps hover:underline"
            href="https://ed.studio/?utm_source=itprenewables.com.au&utm_medium=website&utm_campaign=Site_by_ED"
            target="_blank"
          >
            Site by ED.
          </Link>
        </div>

        <div className="col-span-12 order-10 md:hidden">
          <div className="block h-7" />
        </div>

        <div className="col-span-12 flex flex-wrap gap-0 order-11 md:hidden">
          {OctalArray.map((_, i) => (
            <Fragment key={i}>
              <FooterOctal />
            </Fragment>
          ))}
        </div>

        <div className="col-span-12 order-12 md:order-6 md:col-span-8 md:flex md:justify-start md:items-start md:h-full">
          <span className="block type-ui-xs-caps pt-7 md:pt-0">
            ITP RENEWABLES acknowledges the First Peoples of Australia, their Elders past, present and emerging. We pay
            our respects to the traditional storytellers, designers, artists and custodians of the land on which we live
            and work.
          </span>
        </div>
      </div>
    </div>
  )
}

const FooterOctal = () => {
  const colors = ["white", "pink", "green", "yellow", "orange", "blue"]
  const [currentColor, setCurrentColor] = useState<(typeof colors)[number]>("white")

  return (
    <button
      className={`w-1/4 scale-[1.01] background-transparent appearance-none border-none md:transition md:ease-in md:duration-1000 hover:md:duration-75 text-${currentColor} md:!text-white md:opacity-0 hover:md:opacity-100`}
      onClick={() =>
        setCurrentColor(colors.filter((color) => color !== currentColor)[Math.floor(Math.random() * colors.length)])
      }
    >
      {OCTAL}
    </button>
  )
}
