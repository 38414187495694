import { useIsSSR } from "eddev/routing"
import { useMedia as useMediaClient } from "react-use"

const breakpoints = {
  sm: "(min-width: 600px)",
  md: "(min-width: 900px)",
  lg: "(min-width: 1200px)",
  xl: "(min-width: 1600px)",
  xxl: "(min-width: 2000px)",
}

export function useBreakpoint(query: keyof typeof breakpoints, defaultValue = false) {
  const hydrating = useIsSSR()

  const matches = env.client ? useMediaClient(breakpoints[query]) : false
  return hydrating ? defaultValue : matches
}
