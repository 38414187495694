/**
 * SPA-mode frontend entry.
 * Renders the main app into the empty <div id='root'> element, and expects window._PAGE_DATA to be set.
 */
import "./manifest/blocks";
import "./manifest/views";
import "../../views/index.css";
import { SPARoot } from "eddev/_internal/spa-root.js";
import { devToolsStore } from "eddev/devtools";
import * as React from "react";
import { createRoot } from "react-dom/client";

devToolsStore.loadTailwindConfig = () => import("./manifest/tailwind-config");

createRoot(document.getElementById("root")!).render(<SPARoot />);
