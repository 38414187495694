export const Logo = () => {
  return (
    <svg width="192" height="32" viewBox="0 0 192 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.85654 9.90039H8.88867V15.2963H9.85654V9.90039Z" fill="currentColor" />
      <path d="M15.59 8.7207H10.0898V9.67022H15.59V8.7207Z" fill="currentColor" />
      <path d="M8.65444 8.7207H3.1543V9.67022H8.65444V8.7207Z" fill="currentColor" />
      <path d="M16.794 16.7051H15.8262V22.101H16.794V16.7051Z" fill="currentColor" />
      <path d="M16.794 23.5117H15.8262V28.9076H16.794V23.5117Z" fill="currentColor" />
      <path d="M9.85654 16.7051H8.88867V22.101H9.85654V16.7051Z" fill="currentColor" />
      <path d="M2.91904 16.7051H1.95117V22.101H2.91904V16.7051Z" fill="currentColor" />
      <path d="M23.7315 16.7051H22.7637V22.101H23.7315V16.7051Z" fill="currentColor" />
      <path d="M22.5275 15.5273H17.0273V16.4769H22.5275V15.5273Z" fill="currentColor" />
      <path d="M22.5275 22.332H17.0273V23.2794H22.5275V22.332Z" fill="currentColor" />
      <path
        d="M15.3012 11.5826H17.3193L18.7455 10.1834V8.20582L17.3193 6.80664H15.3012L13.875 8.20582V10.1834L15.3012 11.5826Z"
        fill="currentColor"
      />
      <path
        d="M10.3818 11.5826H8.36371L6.9375 10.1834V8.20582L8.36371 6.80664H10.3818L11.808 8.20582V10.1834L10.3818 11.5826Z"
        fill="currentColor"
      />
      <path
        d="M18.7455 30.6014V28.6238L17.3193 27.2246H15.3012L13.875 28.6238V30.6014L15.3012 32.0006H17.3193L18.7455 30.6014Z"
        fill="currentColor"
      />
      <path
        d="M11.808 23.7948V21.8171L10.3818 20.418H8.36371L6.9375 21.8171V23.7948L8.36371 25.194H10.3818L11.808 23.7948Z"
        fill="currentColor"
      />
      <path
        d="M11.808 16.9881V15.0105L10.3818 13.6113H8.36371L6.9375 15.0105V16.9881L8.36371 18.3873H10.3818L11.808 16.9881Z"
        fill="currentColor"
      />
      <path
        d="M4.87048 23.7948V21.8171L3.44426 20.418H1.42621L0 21.8171V23.7948L1.42621 25.194H3.44426L4.87048 23.7948Z"
        fill="currentColor"
      />
      <path
        d="M4.87048 16.9881V15.0105L3.44426 13.6113H1.42621L0 15.0105V16.9881L1.42621 18.3873H3.44426L4.87048 16.9881Z"
        fill="currentColor"
      />
      <path
        d="M4.87048 10.1834V8.20582L3.44426 6.80664H1.42621L0 8.20582V10.1834L1.42621 11.5826H3.44426L4.87048 10.1834Z"
        fill="currentColor"
      />
      <path
        d="M4.87048 3.3768V1.39918L3.44426 0H1.42621L0 1.39918V3.3768L1.42621 4.77598H3.44426L4.87048 3.3768Z"
        fill="currentColor"
      />
      <path
        d="M15.3012 18.3873H17.3193L18.7455 16.9881V15.0105L17.3193 13.6113H15.3012L13.875 15.0105V16.9881L15.3012 18.3873Z"
        fill="currentColor"
      />
      <path
        d="M22.2387 18.3873H24.2568L25.683 16.9881V15.0105L24.2568 13.6113H22.2387L20.8125 15.0105V16.9881L22.2387 18.3873Z"
        fill="currentColor"
      />
      <path
        d="M15.3012 25.194H17.3193L18.7455 23.7948V21.8171L17.3193 20.418H15.3012L13.875 21.8171V23.7948L15.3012 25.194Z"
        fill="currentColor"
      />
      <path
        d="M22.2387 25.194H24.2568L25.683 23.7948V21.8171L24.2568 20.418H22.2387L20.8125 21.8171V23.7948L22.2387 25.194Z"
        fill="currentColor"
      />
      <path d="M31.9121 25.1768V6.79102H35.9771V25.1746H31.9121V25.1768Z" fill="currentColor" />
      <path d="M42.2041 25.1768V10.0805H38.0078V6.79102H50.4655V10.0805H46.2692V25.1768H42.2041Z" fill="currentColor" />
      <path
        d="M52.498 25.1768V6.79102H59.4133C62.8175 6.79102 65.4318 8.81011 65.4318 12.6169V12.9269C65.4318 16.7337 62.8464 18.7528 59.4133 18.7528H56.5364V25.1746H52.498V25.1768ZM56.5364 15.4677H58.9638C60.2832 15.4677 61.26 14.7168 61.26 12.9313V12.6213C61.26 10.8096 60.2832 10.0849 58.9638 10.0849H56.5364V15.4699V15.4677Z"
        fill="currentColor"
      />
      <path
        d="M69.4668 25.1768V6.79102H76.0394C79.3123 6.79102 81.6619 8.68133 81.6619 12.2284V12.6431C81.6619 15.4393 80.1845 17.2008 77.9417 17.8207L81.9 25.1746H78.6804L74.9336 18.1067H72.3726V25.1746H69.469L69.4668 25.1768ZM72.3704 15.675H75.7746C77.4634 15.675 78.6782 14.7168 78.6782 12.6453V12.2306C78.6782 10.1591 77.5168 9.20083 75.7746 9.20083H72.3704V15.675Z"
        fill="currentColor"
      />
      <path
        d="M82.7715 19.012V18.0035C82.7715 13.9391 84.883 11.5293 88.4719 11.5293C92.4301 11.5293 94.199 14.3255 93.9609 19.0905H85.595V19.3503C85.6484 22.0941 86.5984 23.3121 88.4719 23.3121C90.0561 23.3121 90.8726 22.4062 91.1374 21.0332H93.9097C93.3824 23.6744 91.5868 25.5124 88.4719 25.5124C84.8563 25.5124 82.7715 23.0785 82.7715 19.0141V19.012ZM91.1641 17.1216C91.0328 14.7664 90.214 13.7034 88.4719 13.7034C86.7297 13.7034 85.8064 14.7402 85.6217 17.1216H91.1641Z"
        fill="currentColor"
      />
      <path
        d="M95.9375 25.176V11.8412H98.5496L98.6297 13.7315C99.2638 12.5135 100.476 11.5312 102.325 11.5312C105.071 11.5312 106.206 13.3954 106.206 15.8554V25.176H103.46V16.4513C103.46 14.7945 102.826 13.7839 101.295 13.7839C99.7645 13.7839 98.7098 14.9233 98.7098 16.9162V25.176H95.9375Z"
        fill="currentColor"
      />
      <path
        d="M108.131 19.012V18.0035C108.131 13.9391 110.242 11.5293 113.831 11.5293C117.789 11.5293 119.558 14.3255 119.32 19.0905H110.954V19.3503C111.008 22.0941 111.958 23.3121 113.831 23.3121C115.415 23.3121 116.232 22.4062 116.497 21.0332H119.269C118.742 23.6744 116.946 25.5124 113.831 25.5124C110.216 25.5124 108.131 23.0785 108.131 19.0141V19.012ZM116.523 17.1216C116.392 14.7664 115.573 13.7034 113.831 13.7034C112.089 13.7034 111.166 14.7402 110.981 17.1216H116.523Z"
        fill="currentColor"
      />
      <path
        d="M123.404 25.1766L120.053 11.8418H122.825L125.017 21.8871L127.208 11.8418H129.927L132.17 21.9133L134.386 11.8418H137.105L133.701 25.1766H130.797L128.554 15.4674L126.338 25.1766H123.408H123.404Z"
        fill="currentColor"
      />
      <path
        d="M137.896 19.0382V17.9773C137.896 13.8343 139.823 11.5293 142.727 11.5293C144.6 11.5293 145.893 12.5137 146.5 13.782L146.581 11.8393H149.142V25.174H146.581L146.5 23.2051C145.92 24.4231 144.654 25.5102 142.754 25.5102C139.877 25.5102 137.896 23.2051 137.896 19.036V19.0382ZM146.447 19.4005V17.615C146.447 15.0523 145.417 13.7056 143.517 13.7056C141.721 13.7056 140.693 15.0262 140.693 17.7961V19.2193C140.693 21.9893 141.697 23.3099 143.517 23.3099C145.337 23.3099 146.447 21.9631 146.447 19.4005Z"
        fill="currentColor"
      />
      <path
        d="M154.205 23.2336L154.125 25.1763H151.564V6.01562H154.337V13.4983C154.995 12.3851 156.237 11.5294 157.979 11.5294C160.856 11.5294 162.809 13.8344 162.809 17.9774V19.0382C162.809 23.2074 160.829 25.5125 157.926 25.5125C156.052 25.5125 154.784 24.4516 154.203 23.2336H154.205ZM160.013 19.2194V17.7962C160.013 15.0262 158.983 13.7057 157.189 13.7057C155.289 13.7057 154.259 15.0524 154.259 17.6151V19.4006C154.259 21.9632 155.289 23.31 157.189 23.31C158.985 23.31 160.013 21.9894 160.013 19.2194Z"
        fill="currentColor"
      />
      <path d="M167.64 6.01562V25.1763H164.867V6.01562H167.64Z" fill="currentColor" />
      <path
        d="M169.699 19.012V18.0035C169.699 13.9391 171.811 11.5293 175.4 11.5293C179.358 11.5293 181.127 14.3255 180.889 19.0905H172.523V19.3503C172.576 22.0941 173.526 23.3121 175.4 23.3121C176.984 23.3121 177.8 22.4062 178.065 21.0332H180.837C180.31 23.6744 178.515 25.5124 175.4 25.5124C171.784 25.5124 169.699 23.0785 169.699 19.0141V19.012ZM178.092 17.1216C177.961 14.7664 177.142 13.7034 175.4 13.7034C173.657 13.7034 172.734 14.7402 172.549 17.1216H178.092Z"
        fill="currentColor"
      />
      <path
        d="M182.076 21.0575H184.689C184.873 22.7142 185.743 23.4127 187.169 23.4127C188.462 23.4127 189.281 22.843 189.281 21.5748C189.281 20.4354 188.7 19.8919 186.615 19.4509C184.003 18.9336 182.446 18.0277 182.446 15.5415C182.446 13.0553 184.319 11.5273 187.065 11.5273C189.81 11.5273 191.577 13.0553 191.788 15.6965H189.176C189.045 14.2471 188.306 13.5726 187.038 13.5726C185.77 13.5726 185.085 14.1685 185.085 15.308C185.085 16.4474 185.692 16.9123 187.697 17.3009C190.494 17.8706 192 18.8288 192 21.2627C192 23.8777 190.153 25.5082 187.116 25.5082C184.079 25.5082 182.259 23.9279 182.074 21.0553L182.076 21.0575Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const LogoMark = () => (
  <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.85654 9.90039H8.88867V15.2963H9.85654V9.90039Z" fill="currentColor" />
    <path d="M15.59 8.7207H10.0898V9.67022H15.59V8.7207Z" fill="currentColor" />
    <path d="M8.65444 8.7207H3.1543V9.67022H8.65444V8.7207Z" fill="currentColor" />
    <path d="M16.794 16.7051H15.8262V22.101H16.794V16.7051Z" fill="currentColor" />
    <path d="M16.794 23.5117H15.8262V28.9076H16.794V23.5117Z" fill="currentColor" />
    <path d="M9.85654 16.7051H8.88867V22.101H9.85654V16.7051Z" fill="currentColor" />
    <path d="M2.91904 16.7051H1.95117V22.101H2.91904V16.7051Z" fill="currentColor" />
    <path d="M23.7315 16.7051H22.7637V22.101H23.7315V16.7051Z" fill="currentColor" />
    <path d="M22.5275 15.5273H17.0273V16.4769H22.5275V15.5273Z" fill="currentColor" />
    <path d="M22.5275 22.332H17.0273V23.2794H22.5275V22.332Z" fill="currentColor" />
    <path
      d="M15.3012 11.5826H17.3193L18.7455 10.1834V8.20582L17.3193 6.80664H15.3012L13.875 8.20582V10.1834L15.3012 11.5826Z"
      fill="currentColor"
    />
    <path
      d="M10.3818 11.5826H8.36371L6.9375 10.1834V8.20582L8.36371 6.80664H10.3818L11.808 8.20582V10.1834L10.3818 11.5826Z"
      fill="currentColor"
    />
    <path
      d="M18.7455 30.6014V28.6238L17.3193 27.2246H15.3012L13.875 28.6238V30.6014L15.3012 32.0006H17.3193L18.7455 30.6014Z"
      fill="currentColor"
    />
    <path
      d="M11.808 23.7948V21.8171L10.3818 20.418H8.36371L6.9375 21.8171V23.7948L8.36371 25.194H10.3818L11.808 23.7948Z"
      fill="currentColor"
    />
    <path
      d="M11.808 16.9881V15.0105L10.3818 13.6113H8.36371L6.9375 15.0105V16.9881L8.36371 18.3873H10.3818L11.808 16.9881Z"
      fill="currentColor"
    />
    <path
      d="M4.87048 23.7948V21.8171L3.44426 20.418H1.42621L0 21.8171V23.7948L1.42621 25.194H3.44426L4.87048 23.7948Z"
      fill="currentColor"
    />
    <path
      d="M4.87048 16.9881V15.0105L3.44426 13.6113H1.42621L0 15.0105V16.9881L1.42621 18.3873H3.44426L4.87048 16.9881Z"
      fill="currentColor"
    />
    <path
      d="M4.87048 10.1834V8.20582L3.44426 6.80664H1.42621L0 8.20582V10.1834L1.42621 11.5826H3.44426L4.87048 10.1834Z"
      fill="currentColor"
    />
    <path
      d="M4.87048 3.3768V1.39918L3.44426 0H1.42621L0 1.39918V3.3768L1.42621 4.77598H3.44426L4.87048 3.3768Z"
      fill="currentColor"
    />
    <path
      d="M15.3012 18.3873H17.3193L18.7455 16.9881V15.0105L17.3193 13.6113H15.3012L13.875 15.0105V16.9881L15.3012 18.3873Z"
      fill="currentColor"
    />
    <path
      d="M22.2387 18.3873H24.2568L25.683 16.9881V15.0105L24.2568 13.6113H22.2387L20.8125 15.0105V16.9881L22.2387 18.3873Z"
      fill="currentColor"
    />
    <path
      d="M15.3012 25.194H17.3193L18.7455 23.7948V21.8171L17.3193 20.418H15.3012L13.875 21.8171V23.7948L15.3012 25.194Z"
      fill="currentColor"
    />
    <path
      d="M22.2387 25.194H24.2568L25.683 23.7948V21.8171L24.2568 20.418H22.2387L20.8125 21.8171V23.7948L22.2387 25.194Z"
      fill="currentColor"
    />
  </svg>
)

export const OCTAL = (
  <svg width="100" height="100" viewBox="0 0 249 249" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="octagon-path"
      d="M213.76 115.423H248.242V72.7891L218.095 42.6418L193.713 67.0238L181.299 54.6102L205.681 30.2283L175.534 0.0810547H132.9V34.5632H115.349V0.0810547H72.708L42.5608 30.2283L66.9427 54.6102L54.5365 67.0238L30.1545 42.6418L0 72.7891V115.423H34.4821V132.974H0V175.615L30.1545 205.762L54.5365 181.38L66.9427 193.787L42.5608 218.176L72.708 248.323H115.349V213.841H132.9V248.323H175.534L205.681 218.176L181.299 193.787L193.713 181.38L218.095 205.762L248.242 175.615V132.974H213.76V115.423Z"
      fill="currentColor"
    />
  </svg>
)
