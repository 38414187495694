import { ARROW_BACK } from "@components/atoms/Icons"
import { Logo } from "@components/atoms/Logo"
import { Menu } from "@components/atoms/Menu"
import { usePage, useTagline } from "@hooks/queries"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { useNav } from "@hooks/useNav"
import { usePersonPopout } from "@hooks/usePersonPopout"
import { Link, useRoute } from "eddev/routing"
import { useEffect } from "react"

type HeirarchyT = {
  depth: number
  title: string
}

export function Header() {
  const navStatus = useNav()
  const route = useRoute()
  const tagline = useTagline(undefined).data?.nodeByUri
  const isMd = useBreakpoint("md")
  const isXl = useBreakpoint("xl")
  const personPopout = usePersonPopout()
  const pageTitle = usePage({ uri: route.pathname }).data?.pageBy?.title

  useEffect(() => {
    if (!isMd) navStatus.setOpen(false)
    personPopout.setOpen(false)
  }, [route.pathname])

  useEffect(() => {
    if (isXl) navStatus.setOpen(true)
  }, [isXl])

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  function useRouteHeirarchy() {
    if (route.pathname.includes("/project-category")) {
      const sections = route.pathname.split("/")
      return {
        depth: 1,
        title: `${capitalizeFirstLetter(sections[2])} projects`,
      }
    }

    switch (route.pathname) {
      case "/": {
        return {
          depth: 0,
          title: "Home",
        }
      }

      case "/projects": {
        return {
          depth: 1,
          title: "Projects",
        }
      }

      case "/services": {
        return {
          depth: 1,
          title: "Services",
        }
      }

      case "/thinking": {
        return {
          depth: 1,
          title: "Thinking",
        }
      }

      case "/company": {
        return {
          depth: 1,
          title: "Company",
        }
      }

      case "/company/careers": {
        return {
          depth: 2,
          title: "Careers",
        }
      }

      case "/contact": {
        return {
          depth: 1,
          title: "Contact",
        }
      }

      case "/search": {
        return {
          depth: 1,
          title: "Search",
        }
      }

      default: {
        return {
          depth: 2,
          title: "",
        }
      }
    }
  }

  const heirarchy: HeirarchyT = useRouteHeirarchy()

  return (
    <>
      <div
        id="header"
        className={`fixed top-0 left-0 w-full z-50 transition-all ease-in duration-200 h-headerHeight ${heirarchy.depth === 0 || route.key === "404" ? `bg-grey md:relative` : `bg-hero md:hidden`} ${route.key === "404" ? `md:hidden` : ``}`}
      >
        <div className="grid-auto items-center h-full w-full px-5 md:px-4">
          <div className="col-span-9 flex justify-start py-4">
            {heirarchy.depth === 0 || route.key === "404" ? (
              <i className="block w-[137px] md:w-[192px] h-[23px] md:h-[32px]">
                <Logo />
              </i>
            ) : (
              <>
                {route.key === "single" ? (
                  <Link href="/thinking" className="flex items-center gap-1 type-ui-xs-caps animate-fade">
                    <i className="w-[24px] h-[24px]">{ARROW_BACK}</i> Back to thinking
                  </Link>
                ) : (
                  <>
                    {route.key === "single-project" ? (
                      <Link href="/projects" className="flex items-center gap-1 type-ui-xs-caps animate-fade">
                        <i className="w-[24px] h-[24px]">{ARROW_BACK}</i> Back to projects
                      </Link>
                    ) : (
                      <>
                        {route.key === "single-career" ? (
                          <Link
                            href="/company/careers"
                            className="flex items-center gap-1 type-ui-xs-caps animate-fade"
                          >
                            <i className="w-[24px] h-[24px]">{ARROW_BACK}</i> Back to careers
                          </Link>
                        ) : (
                          <div className="type-title-l-light text-ellipsis overflow-hidden whitespace-nowrap">
                            {heirarchy.title === "" ? pageTitle : heirarchy.title}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="col-span-3 flex justify-end py-4">
            {useRouteHeirarchy().depth === 0 && (
              <div className="type-title-s-light hidden md:block whitespace-nowrap">
                {tagline?.__typename === "Page" && tagline.title}
              </div>
            )}
            <button
              className="h-7 flex type-ui-xs-caps items-center md:hidden"
              onClick={() => navStatus.setOpen(!navStatus.open)}
            >
              Menu
            </button>
          </div>
        </div>
      </div>

      <div
        className={`fixed flex md:hidden flex-col justify-start top-0 left-0 w-screen h-dvh z-50 bg-hero text-reverse transition ease-in-out duration-500 ${navStatus.open ? `translate-x-0` : `translate-x-full pointer-events-none`}`}
      >
        <div className="h-headerHeight grid-auto items-center w-full px-5 md:px-4">
          <div className="col-span-6 flex justify-start items-center h-headerHeight">
            <Link href="/">
              <i className="block w-[137px] h-[23px]">
                <Logo />
              </i>
            </Link>
          </div>
          <div className="col-span-6 flex justify-end items-center h-headerHeight">
            <button
              className="h-7 flex type-ui-xs-caps items-center md:hidden"
              onClick={() => navStatus.setOpen(!navStatus.open)}
            >
              Close
            </button>
          </div>
        </div>

        <div className="flex flex-col justify-between h-full">
          <div className="menu-main">
            <Menu location="Main" />
          </div>
          <div className="menu-secondary pb-10">
            <Menu location="Secondary" />
          </div>
        </div>
      </div>
    </>
  )
}
