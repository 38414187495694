import { blockManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
import core from "../../../blocks/_core";

const manifest = {
  "acf/careers-careers-header": dynamic(() => import("../../../blocks/careers/careers-header.tsx")),
  "acf/careers-careers-list": dynamic(() => import("../../../blocks/careers/careers-list.tsx")),
  "acf/content-accordion-group": dynamic(() => import("../../../blocks/content/accordion-group.tsx")),
  "acf/content-anchor": dynamic(() => import("../../../blocks/content/anchor.tsx")),
  "acf/content-contact-list": dynamic(() => import("../../../blocks/content/contact-list.tsx")),
  "acf/content-gallery": dynamic(() => import("../../../blocks/content/gallery.tsx")),
  "acf/content-logo-carousel": dynamic(() => import("../../../blocks/content/logo-carousel.tsx")),
  "acf/content-section-title": dynamic(() => import("../../../blocks/content/section-title.tsx")),
  "acf/content-text-with-image": dynamic(() => import("../../../blocks/content/text-with-image.tsx")),
  "acf/content-video-background": dynamic(() => import("../../../blocks/content/video-background.tsx")),
  "acf/home-visualisation": dynamic(() => import("../../../blocks/home/visualisation.tsx")),
  "acf/people-people-detail": dynamic(() => import("../../../blocks/people/people-detail.tsx")),
  "acf/people-people-list": dynamic(() => import("../../../blocks/people/people-list.tsx")),
  "acf/project-project-filter": dynamic(() => import("../../../blocks/project/project-filter.tsx")),
  "acf/project-project-header": dynamic(() => import("../../../blocks/project/project-header.tsx")),
  "acf/project-project-team": dynamic(() => import("../../../blocks/project/project-team.tsx")),
  "acf/project-projects-map": dynamic(() => import("../../../blocks/project/projects-map.tsx")),
  "acf/project-related-projects": dynamic(() => import("../../../blocks/project/related-projects.tsx")),
  "acf/thinking-thinking-header": dynamic(() => import("../../../blocks/thinking/thinking-header.tsx")),
  ...core,
};

const info = {
  "acf/careers-careers-header": { "slug": "careers/careers-header", "tags": ["root"], "flags": {} },
  "acf/careers-careers-list": { "slug": "careers/careers-list", "tags": ["root"], "flags": {} },
  "acf/content-accordion-group": { "slug": "content/accordion-group", "tags": ["root"], "flags": {} },
  "acf/content-anchor": { "slug": "content/anchor", "tags": ["root"], "flags": {} },
  "acf/content-contact-list": { "slug": "content/contact-list", "tags": ["root"], "flags": {} },
  "acf/content-gallery": { "slug": "content/gallery", "tags": ["root"], "flags": {} },
  "acf/content-logo-carousel": { "slug": "content/logo-carousel", "tags": ["root"], "flags": {} },
  "acf/content-section-title": { "slug": "content/section-title", "tags": ["root"], "flags": {} },
  "acf/content-text-with-image": { "slug": "content/text-with-image", "tags": ["root"], "flags": {} },
  "acf/content-video-background": { "slug": "content/video-background", "tags": ["root"], "flags": {} },
  "acf/home-visualisation": { "slug": "home/visualisation", "tags": ["root"], "flags": {} },
  "acf/people-people-detail": { "slug": "people/people-detail", "tags": ["root"], "flags": {} },
  "acf/people-people-list": { "slug": "people/people-list", "tags": ["root"], "flags": {} },
  "acf/project-project-filter": { "slug": "project/project-filter", "tags": ["root"], "flags": {} },
  "acf/project-project-header": { "slug": "project/project-header", "tags": ["root"], "flags": {} },
  "acf/project-project-team": { "slug": "project/project-team", "tags": ["root"], "flags": {} },
  "acf/project-projects-map": { "slug": "project/projects-map", "tags": ["root"], "flags": {} },
  "acf/project-related-projects": { "slug": "project/related-projects", "tags": ["root"], "flags": {} },
  "acf/thinking-thinking-header": { "slug": "thinking/thinking-header", "tags": ["root"], "flags": {} },
};

blockManifestReader.set(manifest, info);

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}
