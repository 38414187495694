import { Link } from "eddev/routing"

export function Button({
  children,
  href,
  target,
  onClick,
  className,
  reverse,
}: {
  children: string | JSX.Element | JSX.Element[]
  href?: string
  target?: string
  onClick?: () => void
  className?: string
  reverse?: boolean
}) {
  const classes = `appearance-none flex items-center justify-center gap-1 w-full md:w-auto text-center px-6 py-3 md:py-2 border border-black cursor-pointer type-ui-xs-caps md:type-ui-m-light transition-all ease-in duration-100 ${reverse ? `bg-black text-hero hover:bg-hero hover:text-black` : `bg-transparent text-black hover:bg-black hover:text-white hover:border-black`} whitespace-nowrap [&>svg]:!w-[20px] [&>svg]:!h-[20px] ${className ? className : ``}`
  if (href)
    return (
      <Link className={classes} href={href} target={target ?? undefined}>
        {children}
      </Link>
    )

  return (
    <button className={classes} onClick={onClick ?? undefined}>
      {children}
    </button>
  )
}
