import { useNav } from "@hooks/useNav"
import { ContentBlock } from "eddev/blocks"
import { useRoute } from "eddev/routing"

const HTMLBlock = (props: ContentBlock) => {
  const route = useRoute()
  const navStatus = useNav()

  if (typeof props.innerHTML === "string") {
    return (
      <div className="core grid-auto max-w-full">
        <div
          className={`type-body-m-regular col-span-12 my-4 md:max-w-maxText`}
          dangerouslySetInnerHTML={{ __html: props.innerHTML }}
        />
      </div>
    )
  } else {
    return null
  }
}

const HeadingBlock = (props: ContentBlock) => {
  const route = useRoute()

  if (typeof props.innerHTML === "string") {
    return (
      <div className="core grid-auto max-w-full relative mt-5 md:mt-0">
        <div className="md:hidden oversize border-t md:border-none -ml-5 absolute top-0" />
        <div
          className={`type-body-m-regular col-span-12 pt-4 md:pt-2 mt-6 mb-2 md:mb-1 ${route.key === "single-project" ? `md:col-span-8` : `md:col-span-10`}`}
          dangerouslySetInnerHTML={{ __html: props.innerHTML }}
        />
      </div>
    )
  } else {
    return null
  }
}

const ImageBlock = (props: ContentBlock) => {
  const fullWidth = props.innerHTML?.includes("is-style-full-width")
  if (typeof props.innerHTML === "string") {
    return (
      <div
        className={`grid-auto image-block ${fullWidth ? `[&>figure]:col-span-12 w-screen md:w-full` : `max-w-full`}`}
        dangerouslySetInnerHTML={{ __html: props.innerHTML }}
      />
    )
  } else {
    return null
  }
}

export default {
  // "core/rich-text": HTMLBlock,
  "core/paragraph": HTMLBlock,
  "core/heading": HeadingBlock,
  "core/list": HTMLBlock,
  "core/image": ImageBlock,
  "core/buttons": HTMLBlock,
  "core/embed": HTMLBlock,
  // "core/embed": (props) => {
  //   return <div>{JSON.stringify(props)}</div>
  // },
}
